import React from 'react';

import Menu from './components/menu';
import DateFilter from './components/date-filter';
import TitleBar from './components/title-bar';
import Filters from './components/filters';

import {Container} from './styles';
import RequestsTable from './components/requests-table';

const Logs: React.FC = () => {
  return (
    <>
      <Menu />
      <Container>
        <TitleBar />
        <DateFilter />
        <Filters />
        <RequestsTable />
      </Container>
    </>
  );
};

export default Logs;
