import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 20px 3%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;
