import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import filter from 'module/logs/@redux';
import authentication from 'module/authentication/@redux';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filter', 'authentication'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({filter, authentication}),
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false}),
});
const persistor = persistStore(store);

export {store, persistor};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
