import {format, isSameDay, subDays} from 'date-fns';
import {changeDates} from 'module/logs/@redux';
import React, {useEffect, useState} from 'react';
import Calendar from 'shared/components/calendar';
import {useAppDispatch, useAppSelector} from 'shared/hooks/use-redux';

import {Container, Option, CalendarIcon} from './styles';

const DateFilter: React.FC = () => {
  const {startDate, endDate, date} = useAppSelector((state) => state.filter);
  const [isShowingCalendar, showCalendar] = useState(false);
  const dispatch = useAppDispatch();

  const closeCalendar = () => showCalendar(false);

  const confirm = (startDate: Date, endDate: Date) => {
    dispatch(changeDates({startDate, endDate, date: undefined}));
  };

  const changeDate = (date: Date) => {
    dispatch(changeDates({startDate: undefined, endDate: undefined, date}));
  };

  useEffect(() => {
    closeCalendar();
  }, [startDate, endDate]);

  return (
    <>
      <Container>
        <Option
          onClick={() => showCalendar(true)}
          hover={isShowingCalendar}
          active={(startDate && endDate) !== undefined}>
          <CalendarIcon />
          {startDate && endDate
            ? `${format(new Date(startDate), "LLL dd',' yyyy")} - ${format(
                new Date(endDate),
                "LLL dd',' yyyy",
              )}`
            : 'Custom'}
          {isShowingCalendar && (
            <Calendar
              {...{
                confirm,
                closeCalendar,
                startDate,
                endDate,
              }}
            />
          )}
        </Option>
        <Option
          onClick={() => changeDate(new Date())}
          active={date && isSameDay(new Date(date), new Date())}>
          Today
        </Option>
        <Option
          onClick={() => changeDate(subDays(new Date(), 1))}
          active={date && isSameDay(new Date(date), subDays(new Date(), 1))}>
          Yesterday
        </Option>
      </Container>
    </>
  );
};

export default DateFilter;
