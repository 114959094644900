import {createSlice} from '@reduxjs/toolkit';
import {cases} from './async-thunks';
import * as reducers from './reducers';

export interface Request {
  branch: string;
  url: string;
  statusCode: number;
  connected: boolean;
  driverId: number;
  driverName: string;
  method: string;
  date: string;
  body: any;
  response: any;
  headers: any;
}

export enum Operators {
  Equal = '=',
  NotEqual = '≠',
}

export type FieldOptions = keyof Request;

export interface Filter {
  field: FieldOptions;
  operator: Operators;
  value: any | any[];
}

interface FilterOption {
  field: FieldOptions;
  values?: boolean[] | number[] | string[];
  centerValue?: boolean;
  type: 'single' | 'multiple' | 'input';
}

export const filterOptions: FilterOption[] = [
  {
    field: 'branch',
    type: 'input',
  },
  {
    field: 'method',
    values: ['GET', 'POST', 'PUT', 'DELETE'],
    type: 'multiple',
  },
  {
    field: 'statusCode',
    values: [200, 201, 404, 400, 500],
    centerValue: true,
    type: 'multiple',
  },
  {
    field: 'driverId',
    type: 'input',
  },
  {
    field: 'connected',
    values: [true, false],
    type: 'single',
  },
  {
    field: 'url',
    type: 'input',
  },
];

export interface LogsState {
  filters: Filter[];
  logs: Request[];
  loading: boolean;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  lastUpdate: Date;
}

export const initialState: LogsState = {
  filters: [{field: 'branch', operator: Operators.Equal, value: 'expresso'}],
  logs: [],
  loading: false,
  date: new Date(),
  lastUpdate: new Date(),
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers,
  extraReducers: (builder) => cases.forEach((thunkCase) => thunkCase(builder)),
});

export const {addFilter, editFilter, removeFilter, changeDates} =
  filterSlice.actions;
export default filterSlice.reducer;
