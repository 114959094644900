import {
  addMonths,
  getDaysInMonth,
  getMonth,
  getYear,
  startOfMonth,
  subMonths,
  format,
  startOfDay,
  isSameDay,
  isWithinInterval,
  isAfter,
} from 'date-fns';
import React, {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import {
  Container,
  DateInputs,
  Input,
  CalendarHeader,
  PrevMonth,
  NextMonth,
  PrevIcon,
  NextIcon,
  Month,
  Table,
  TableHeader,
  HeaderRow,
  HeaderColumn,
  TableBody,
  Row,
  Column,
  Day,
  Empty,
  Hover,
  Footer,
  Confirm,
} from './styles';

interface WeekDay {
  day?: number;
  weekDay: string;
}

interface Props {
  startDate?: Date;
  endDate?: Date;
  confirm: (startDate: Date, endDate: Date) => void;
  closeCalendar: () => void;
}

const Calendar: React.FC<Props> = ({confirm, closeCalendar, ...props}) => {
  const ref = useOnclickOutside(closeCalendar);
  const [currentMonth, setCurrentMonth] = useState(
    startOfMonth(props.endDate ? new Date(props.endDate) : new Date()),
  );
  const previousMonth = subMonths(startOfMonth(currentMonth), 1);
  const nextMonth = addMonths(startOfMonth(currentMonth), 1);
  const month = getMonth(currentMonth);
  const year = getYear(currentMonth);
  const quantityOfDays = getDaysInMonth(currentMonth);

  const [startDate, setStartDate] = useState<Date | undefined>(
    props.startDate ? new Date(props.startDate) : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.endDate ? new Date(props.endDate) : undefined,
  );
  const [hover, setHover] = useState<Date | undefined>(
    props.endDate ? new Date(props.endDate) : undefined,
  );

  const changeDate = (day: number) => {
    const date = startOfDay(new Date(year, month, day));
    if (!startDate) setStartDate(date);
    else if (!endDate && isAfter(startDate, date)) {
      setEndDate(startDate);
      setStartDate(date);
      setHover(startDate);
    } else if (!endDate) setEndDate(date);
    else {
      setEndDate(undefined);
      setHover(undefined);
      setStartDate(date);
    }
  };

  const generateCalendarDays = () => {
    const calendar: WeekDay[] = [];
    for (let i = 1; i <= quantityOfDays; i++) {
      const date = new Date(year, month, i);
      calendar.push({
        day: i,
        weekDay: format(date, 'EEEEEE'),
      });
    }

    return calendar;
  };

  const weekMockup = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const calendar = (): WeekDay[] => {
    const calendar = generateCalendarDays();
    const day1 = calendar[0];

    const insertFrom = weekMockup.indexOf(day1.weekDay);

    const calendarMockup = Array.from(
      Array(insertFrom < 5 ? 5 : 6),
      () => weekMockup,
    ).reduce((a, b) => [...a, ...b]);

    return calendarMockup.map((weekDay, index) => {
      if (index < insertFrom) return {weekDay};

      const isDate = calendar[index - insertFrom];

      if (isDate) return isDate;

      return {weekDay};
    });
  };

  function sliceIntoChunks(arr: WeekDay[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const dateByDayNumber = (day: number) => new Date(year, month, day);

  const validIsSameDay = (leftDate: Date, rightDate?: Date) =>
    rightDate ? isSameDay(rightDate, leftDate) : false;

  const isSameDayOfStartDate = (day: number) =>
    validIsSameDay(dateByDayNumber(day), startDate);

  const isSameDayOfEndDate = (day: number) =>
    validIsSameDay(dateByDayNumber(day), endDate);

  const isSameDayOfHover = (day: number) =>
    validIsSameDay(dateByDayNumber(day), hover);

  const isBetweenStartDateAndHover = (day: number) =>
    startDate && hover
      ? isAfter(hover, startDate)
        ? isWithinInterval(dateByDayNumber(day), {start: startDate, end: hover})
        : isWithinInterval(dateByDayNumber(day), {start: hover, end: startDate})
      : false;

  const hoverOffsetStart = (day: number) =>
    isAfter(hover!, startDate!)
      ? {
          start: isSameDayOfStartDate(day),
          end: isSameDayOfEndDate(day) || isSameDayOfHover(day),
        }
      : {
          start: isSameDayOfHover(day),
          end: isSameDayOfStartDate(day),
        };

  const canConfirm =
    (startDate && endDate) !== undefined &&
    (startDate?.getTime() !==
      (props.startDate ? new Date(props.startDate).getTime() : undefined) ||
      endDate?.getTime() !==
        (props.endDate ? new Date(props.endDate).getTime() : undefined));

  return (
    <Container {...{ref}}>
      <CalendarHeader>
        <PrevMonth onClick={() => setCurrentMonth(previousMonth)}>
          <PrevIcon />
        </PrevMonth>
        <Month>{format(currentMonth, 'MMMM yyyy')}</Month>
        <NextMonth onClick={() => setCurrentMonth(nextMonth)}>
          <NextIcon />
        </NextMonth>
      </CalendarHeader>
      <DateInputs>
        <Input>
          {startDate ? format(startDate, "LLL dd',' yyyy") : 'Start date'}
        </Input>
        <Input>
          {endDate ? format(endDate, "LLL dd',' yyyy") : 'End date'}
        </Input>
      </DateInputs>
      <Table>
        <TableHeader>
          <HeaderRow>
            {weekMockup.map((weekDay, index) => (
              <HeaderColumn key={index}>{weekDay}</HeaderColumn>
            ))}
          </HeaderRow>
        </TableHeader>
        <TableBody onMouseLeave={() => !endDate && setHover(undefined)}>
          {sliceIntoChunks(calendar(), 7).map((week, index) => (
            <Row key={index}>
              {week.map(({day}, index) =>
                !day ? (
                  <Empty />
                ) : (
                  <Column
                    isDay={day}
                    key={index}
                    onClick={() => changeDate(day)}
                    onMouseEnter={() =>
                      !isSameDayOfStartDate(day)
                        ? !endDate && setHover(dateByDayNumber(day))
                        : !endDate && setHover(undefined)
                    }>
                    <Day
                      hover={isSameDayOfHover(day)}
                      active={
                        isSameDayOfStartDate(day) || isSameDayOfEndDate(day)
                      }>
                      {day}
                    </Day>
                    {isBetweenStartDateAndHover(day) && (
                      <Hover {...hoverOffsetStart(day)} />
                    )}
                  </Column>
                ),
              )}
            </Row>
          ))}
        </TableBody>
      </Table>
      <Footer>
        <Confirm
          onClick={() => canConfirm && confirm(startDate!, endDate!)}
          active={canConfirm}>
          Confirm
        </Confirm>
      </Footer>
    </Container>
  );
};

export default Calendar;
