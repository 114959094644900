import {
  addFilter,
  editFilter,
  Filter,
  filterOptions,
  removeFilter,
} from 'module/logs/@redux';
import {getLogs} from 'module/logs/@redux/async-thunks/get-logs';
import React, {useEffect, useState} from 'react';
import SelectFilter from 'shared/components/select-filter';
import {useAppDispatch, useAppSelector} from 'shared/hooks/use-redux';

import {Container, Title, AddButton, AddIcon} from './styles';

const Filters: React.FC = () => {
  const {filters, startDate, endDate, date} = useAppSelector(
    (state) => state.filter,
  );
  const dispatch = useAppDispatch();
  const [newFilter, showNewFilter] = useState(false);

  const createFilter = (filter: Filter) => {
    dispatch(addFilter(filter));
    showNewFilter(false);
  };

  const updateFilter = (index: number) => (filter: Filter) => {
    dispatch(editFilter({filter, index}));
  };

  useEffect(() => {
    dispatch(getLogs());
  }, [filters, startDate, endDate, date]);

  return (
    <Container>
      <Title>FILTERS</Title>
      {filters.map((filter, index) => (
        <SelectFilter
          key={index}
          index={index}
          initialFilter={filter}
          remove={() => dispatch(removeFilter(index))}
          handleAction={updateFilter(index)}
        />
      ))}
      {newFilter && (
        <SelectFilter
          index={1}
          remove={() => showNewFilter(false)}
          handleAction={createFilter}
        />
      )}
      {filters.length !== filterOptions.length && !newFilter && (
        <AddButton onClick={() => showNewFilter(true)}>
          <AddIcon />
          Add
        </AddButton>
      )}
    </Container>
  );
};

export default Filters;
