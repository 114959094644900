import React, {useState} from 'react';
import {Filter} from 'module/logs/@redux';
import DefaultFilter from '../default-filter';

interface Props {
  initialFilter?: Filter;
  remove: () => void;
  handleAction: (filter: Filter) => void;
  index: number;
}

const SelectFilter: React.FC<Props> = ({
  initialFilter,
  index,
  handleAction,
  remove,
}) => {
  const [value, setValue] = useState<any>();

  return (
    <DefaultFilter
      {...{
        value,
        setValue,
        initialFilter,
        index,
        handleAction,
        remove,
      }}
    />
  );
};

export default SelectFilter;
