import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {AuthenticationState} from '..';
import api from 'shared/services/api';

interface Props {
  login?: string;
  password?: string;
}

export const login = createAsyncThunk(
  'authentication/login',
  async (data: Props, {rejectWithValue}) => {
    try {
      return await api({
        method: 'POST',
        url: '/login',
        data,
      });
    } catch (error) {
      return rejectWithValue((error as any).response.data.message);
    }
  },
);

export const loginCases = (
  builder: ActionReducerMapBuilder<AuthenticationState>,
) => {
  builder.addCase(login.fulfilled, (state, action) => {
    state.token = action.payload.data.token;
  });
};
