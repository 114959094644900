import React, {FormEvent, useState} from 'react';
import {login as loginConnection} from 'module/authentication/@redux/async-thunks/login';
import {useAppDispatch} from 'shared/hooks/use-redux';

import {
  Container,
  Logo,
  Title,
  Form,
  Label,
  Input,
  Button,
  Loader,
} from './styles';
import {toast} from 'react-toastify';

const Login: React.FC = () => {
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);
    const {meta, payload} = await dispatch(loginConnection({login, password}));
    setLoading(false);

    if (meta.requestStatus === 'rejected')
      toast.error(payload as string, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  };

  return (
    <Container>
      <Form onSubmit={handleLogin}>
        <Logo src="logo.png" />
        <Title>Control Tower - Logs</Title>
        <Label>User</Label>
        <Input onChange={(e) => setLogin(e.target.value)} />
        <Label>Password</Label>
        <Input type="password" onChange={(e) => setPassword(e.target.value)} />
        <Button onClick={handleLogin}>{loading ? <Loader /> : 'Login'}</Button>
      </Form>
    </Container>
  );
};

export default Login;
