import {formatDistance} from 'date-fns';
import {getLogs} from 'module/logs/@redux/async-thunks/get-logs';
import React from 'react';
import {useAppDispatch, useAppSelector} from 'shared/hooks/use-redux';

import {Container, Title, Reload, Description, Loader} from './styles';

const TitleBar: React.FC = () => {
  const {loading, lastUpdate} = useAppSelector((state) => state.filter);
  const dispatch = useAppDispatch();

  const refreshLogs = () => dispatch(getLogs());

  return (
    <Container>
      <Title>Logs</Title>
      {loading ? <Loader /> : <Reload onClick={refreshLogs} />}
      <Description>
        {loading
          ? 'Fetching logs...'
          : `Updated ${formatDistance(new Date(), new Date(lastUpdate))} ago`}
      </Description>
    </Container>
  );
};

export default TitleBar;
