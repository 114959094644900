import colors from 'shared/colors';
import styled from 'styled-components';
import Spinner from 'react-spinners/ClipLoader';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${colors.primary};
`;

export const Form = styled.form`
  width: 450px;
  border-radius: 5px;
  background-color: white;
  padding: 30px 30px 60px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const Logo = styled.img`
  height: 120px;
  object-fit: contain;
`;

export const Title = styled.h2`
  width: 100%;
  text-align: center;
`;

export const Label = styled.label``;

export const Input = styled.input`
  height: 45px;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid ${colors.gray};

  &:focus {
    outline: 1px solid ${colors.primary};
  }
`;

export const Button = styled.button`
  margin-top: 10px;
  width: 100%;
  height: 45px;
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${colors.primaryHover};
  }
`;

export const Loader = styled(Spinner).attrs({
  size: 18,
  color: colors.white,
})``;
