import colors from 'shared/colors';
import styled from 'styled-components';
import {IoAdd} from 'react-icons/io5';

export const Container = styled.div`
  border: 1px solid ${colors.lightGray};
  padding: 15px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  font-size: 12px;
  color: ${colors.gray};
  font-weight: 600;
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  align-self: flex-start;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  border-radius: 5px;
  gap: 5px;

  &:hover {
    transition: all ease-in-out 0.2s;
    color: ${colors.primary};
    background-color: ${colors.primaryLight};
  }
`;

export const AddIcon = styled(IoAdd)`
  font-size: 18px;
  background-color: transparent;
`;
