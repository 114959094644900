import {PayloadAction} from '@reduxjs/toolkit';
import {LogsState} from '..';

interface Props {
  date?: Date;
  startDate?: Date;
  endDate?: Date;
}

export default (state: LogsState, action: PayloadAction<Props>) => {
  state.startDate = action.payload.startDate;
  state.endDate = action.payload.endDate;
  state.date = action.payload.date;
};
