import {createSlice} from '@reduxjs/toolkit';
import {cases} from './async-thunks';
import * as reducers from './reducers';

export interface AuthenticationState {
  token?: string;
}

export const initialState: AuthenticationState = {};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers,
  extraReducers: (builder) => cases.forEach((thunkCase) => thunkCase(builder)),
});

export const {logout} = filterSlice.actions;
export default filterSlice.reducer;
