import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {useAppSelector} from 'shared/hooks/use-redux';
import {JSONTree} from 'react-json-tree';
import * as themes from 'redux-devtools-themes';

import {
  Table,
  TableHeader,
  HeaderRow,
  HeaderColumn,
  TableBody,
  Row,
  Column,
  EmptyColumn,
  LoaderContainer,
  Loader,
  OpenedColumn,
  OpenedBody,
  ExpandedRow,
} from './styles';

const RequestsTable: React.FC = () => {
  const {logs, loading, filters, startDate, endDate, date} = useAppSelector(
    (state) => state.filter,
  );
  const [opened, open] = useState<number[]>([]);

  const handleRowClick = (index: number) => {
    const isIndex = opened.indexOf(index);

    if (isIndex === -1) return open([...opened, index]);

    open(opened.splice(isIndex, isIndex));
  };

  useEffect(() => {
    open([]);
  }, [filters, startDate, endDate, date]);

  const renderJsonTree = (data: any) => (
    <JSONTree
      invertTheme
      data={JSON.parse(data)}
      theme={themes.atelierSeaside}
      labelRenderer={([key]) => (
        <em style={{fontSize: 14, fontWeight: 600}}>{key}</em>
      )}
      valueRenderer={(raw) => <em style={{fontSize: 14}}>{raw}</em>}
    />
  );

  return (
    <Table>
      <TableHeader>
        <HeaderRow>
          <HeaderColumn>method</HeaderColumn>
          <HeaderColumn>date</HeaderColumn>
          <HeaderColumn>statusCode</HeaderColumn>
          <HeaderColumn>url</HeaderColumn>
          <HeaderColumn>driverId</HeaderColumn>
          <HeaderColumn>driverName</HeaderColumn>
          <HeaderColumn>connected</HeaderColumn>
        </HeaderRow>
      </TableHeader>
      <TableBody>
        {!loading &&
          logs.map(
            (
              {
                method,
                statusCode,
                url,
                driverId,
                driverName,
                connected,
                date,
                body,
                response,
                headers,
              },
              index,
            ) => (
              <>
                <Row key={index} onClick={() => handleRowClick(index)}>
                  <Column>{method}</Column>
                  <Column>{format(new Date(date), 'dd/MM/yyyy HH:mm')}</Column>
                  <Column>{statusCode}</Column>
                  <Column>{url}</Column>
                  <Column>{driverId}</Column>
                  <Column>{driverName}</Column>
                  <Column>{`${connected}`}</Column>
                </Row>
                {opened.includes(index) && (
                  <ExpandedRow>
                    <OpenedColumn>
                      <OpenedBody>
                        <h3>Request</h3>
                        {renderJsonTree(body)}
                        <h3>Response</h3>
                        {renderJsonTree(response)}
                        <h3>Headers</h3>
                        {renderJsonTree(headers)}
                      </OpenedBody>
                    </OpenedColumn>
                  </ExpandedRow>
                )}
              </>
            ),
          )}
        {!loading && logs.length === 0 && (
          <Row>
            <EmptyColumn>Nothing found for current filter</EmptyColumn>
          </Row>
        )}
        {loading && (
          <Row>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

export default RequestsTable;
