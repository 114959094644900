import styled from 'styled-components';
import colors from 'shared/colors';
import Spinner from 'react-spinners/ClipLoader';

export const Table = styled.table`
  border: 1px solid ${colors.lightGray};
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  width: 100%;
  position: sticky;
  top: 59px;
`;

export const HeaderRow = styled.tr``;

export const HeaderColumn = styled.td`
  padding: 10px;
  font-size: 12px;
  border-bottom: 1px solid ${colors.lightGray};
  background-color: ${colors.softGray};
  font-weight: bold;
  color: ${colors.darkGray};
`;

export const TableBody = styled.tbody`
  width: 100%;
  background-color: blue;
`;

export const Row = styled.tr`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:last-child td {
    border-bottom: none;
  }

  &:hover td {
    transition: all 0.2s ease-in-out;
    background-color: ${colors.softGray};
  }
`;

export const Column = styled.td`
  padding: 10px;
  font-size: 12px;
  border-bottom: 1px solid ${colors.lightGray};
  color: ${colors.gray};
  font-weight: 600;
`;

export const EmptyColumn = styled.td.attrs({colSpan: 6})`
  padding: 10px;
  font-size: 12px;
  border-bottom: 1px solid ${colors.lightGray};
  color: ${colors.darkGray};
  font-weight: 600;
  text-align: center;
`;

export const LoaderContainer = styled.td.attrs({colSpan: 6})`
  height: 38px;
  flex: 1;
  text-align: center;
`;

export const Loader = styled(Spinner).attrs({
  size: 18,
  color: colors.primary,
})``;

export const OpenedColumn = styled.td.attrs({colSpan: 7})`
  border-bottom: 1px solid ${colors.lightGray};
`;

export const OpenedBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  gap: 5px;

  h3 {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ExpandedRow = styled.tr`
  cursor: pointer;
  &:last-child td {
    border-bottom: none;
  }
`;
