import React from 'react';
import {Operators} from 'module/logs/@redux';

import {Container, Input, OptionList, Option} from './styles';

type Types = string | number | Operators | undefined;

type Props<Type extends Types> = {
  value: Type;
  onChange: (value: Type) => void;
  options: Type[];
  search?: boolean;
  centerValue?: boolean;
};

function SingleSelect<Type extends Types>({
  onChange,
  value,
  options,
  search = false,
  centerValue = false,
}: Props<Type>) {
  return (
    <Container full={search}>
      {search && <Input placeholder="Search" autoFocus />}
      <OptionList>
        {options.map((option, index) => {
          const active = value === option;
          return (
            <Option
              className="ignore"
              {...{centerValue, active}}
              key={index}
              onClick={() => onChange(option)}>
              {`${option}`}
            </Option>
          );
        })}
      </OptionList>
    </Container>
  );
}

export default SingleSelect;
