import React, {useState} from 'react';

import {
  Container,
  Input,
  OptionList,
  CheckBox,
  Active,
  Option,
  AddButtom,
} from './styles';

type Types = string | number | undefined;

type Props<Type extends Types> = {
  values: Type[];
  onChange: (value: Type[]) => void;
  options: Type[];
  search?: boolean;
  centerValue?: boolean;
};

function MultiSelect<Type extends Types>({
  onChange,
  values = [],
  options,
  search = false,
}: Props<Type>) {
  const [localValues, setLocalValues] = useState<Type[]>(values ?? []);

  const changeLocalValues = (value: Type) => {
    const isValue = localValues.find((localValue) => localValue === value);

    setLocalValues(
      isValue
        ? localValues.filter((localValue) => localValue !== isValue)
        : [...localValues, value],
    );
  };

  return (
    <Container full={search}>
      {search && <Input placeholder="Search" autoFocus />}
      <OptionList>
        {options.map((option, index) => {
          const active = localValues.includes(option) ? true : false;
          return (
            <Option
              {...{active}}
              key={index}
              onClick={() => changeLocalValues(option)}>
              <CheckBox {...{active}}>{active && <Active />}</CheckBox>
              {`${option}`}
            </Option>
          );
        })}{' '}
        <AddButtom onClick={() => onChange(localValues)}>Add</AddButtom>
      </OptionList>
    </Container>
  );
}

export default MultiSelect;
