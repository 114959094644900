import styled from 'styled-components';
import colors from 'shared/colors';

export const Container = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${colors.primary};
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 60px;
  z-index: 10020;
`;

export const Logo = styled.img`
  object-fit: fill;
  height: 45px;
  background-color: transparent;
`;

export const Logout = styled.button`
  padding: 5px 15px;
  height: 45px;
  border-radius: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkPrimary};
    transition: all 0.2s ease-in-out;
  }
`;
