import colors from 'shared/colors';
import styled from 'styled-components';
import {IoCalendarOutline} from 'react-icons/io5';

interface Props {
  active?: boolean;
  hover?: boolean;
}

export const Container = styled.div`
  height: 30px;
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  border-radius: 8px;
  z-index: 2000;

  display: flex;
  align-self: flex-start;
`;

export const Option = styled.div<Props>`
  position: relative;
  height: 100%;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  background-color: ${(props) => props.active && colors.softGray};
  color: ${(props) => (props.active ? colors.darkGray : colors.opaqueText)};
  border-right: 1px solid ${colors.lightGray};

  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1000;

  &:first-child {
    border-left: 1px solid ${colors.lightGray};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    color: ${colors.primary};
    background-color: ${colors.primaryLight};
    transition: all ease-in-out 0.2s;

    svg {
      color: ${colors.primary};
    }
  }
  ${(props) =>
    props.hover &&
    `
      color: ${colors.primary};
      background-color: ${colors.primaryLight};
      transition: all ease-in-out 0.2s;

      svg {
        color: ${colors.primary};
      }
  `}
`;

export const CalendarIcon = styled(IoCalendarOutline)<Props>`
  font-size: 18px;
  color: ${colors.opaqueText};
  transition: all ease-in-out 0.2s;
  background-color: transparent;
`;
