export default {
  primary: '#4f44df',
  primaryHover: '#463dbb',
  primaryLight: 'rgba(79, 68, 224, .1)',
  darkPrimary: '#322e6f',
  softGray: '#f6f6f6',
  lightGray: '#e9e9e9',
  gray: '#8f8f91',
  darkGray: '#2a2a2f',
  opaqueText: '#8f8f91',
  white: 'white',
  red: '#e34f2f',
  redSoft: 'rgba(227, 79, 47, .1)',
  transparent: 'transparent',
};

// --gray0: #fff;
// --gray1: #f6f6f6;
// --gray2: #e9e9e9;
// --gray3: #8f8f91;
// --gray4: #626266;
// --gray5: #2a2a2f;
// --gray0-rgb: 255,255,255;
// --gray1-rgb: 246,246,246;
// --gray2-rgb: 233,233,233;
// --gray3-rgb: 143,143,145;
// --gray4-rgb: 98,98,102;
// --gray5-rgb: 42,42,47;
// --blue1: #4f44df;
// --blue2: #463dbb;
// --blue3: #322e6f;
// --green1: #219464;
// --green2: #217d57;
// --green3: #204e3e;
// --red1: #e34f2f;
// --red2: #bc452d;
// --red3: #6d3228;
// --orange1: #df7800;
// --orange2: #b96607;
// --orange3: #6c4316;
// --blue1-rgb: 79,68,224;
// --blue2-rgb: 70,61,187;
// --blue3-rgb: 50,46,111;
// --green1-rgb: 33,148,100;
// --green2-rgb: 33,125,87;
// --green3-rgb: 32,78,62;
// --red1-rgb: 227,79,47;
// --red2-rgb: 188,69,45;
// --red3-rgb: 109,50,40;
// --orange1-rgb: 223,120,0;
// --orange2-rgb: 185,102,7;
// --orange3-rgb: 108,67,22;
// --text-highlight-color: #accef7;
// --chrome-color: #4f44e0;
// --chrome-active-color: #322e6f;
// --chrome-hover-color: #463dbb;
// --chrome-search-bar-color: #463dbb;
// --chrome-text-color: #e9e9e9;
// --chrome-active-text-color: #fff;
// --primary-text-color: var(--gray5);
// --secondary-text-color: var(--gray4);
// --tertiary-text-color: var(--gray3);
// --disabled-text-color: var(--gray3);
// --component-hover-color: var(--gray1);
// --component-active-color: var(--gray1);
// --component-divider-color: var(--gray1);
// --component-outline-color: var(--gray2);
// --report-background-color: var(--gray1);
// --background-color: var(--gray0);
// --background-color-rgb: var(--gray0-rgb);
// --secondary-background-color: var(--gray1);
// --inverted-component-background-color: var(--gray5);
// --inverted-component-secondary-background-color: var(--gray4);
// --inverted-component-text-color: var(--gray0);
// --inverted-component-secondary-text-color: var(--gray1);
// --upsell-color: var(--green1);
// --chart-axis-label-color: var(--gray4);
// --chart-axis-grid-color: var(--gray2);
// --chart-axis-line-color: var(--gray2);
// --chart-axis-tick-marker-color: var(--gray2);
// --chart-crosshair-line-color: var(--gray2);
// --chart-annotations-line-color: var(--gray2);
// --chart-highlight-color: var(--blue1);
// --chart-highlight-color-rgb: 79,68,224;
// --drop-box-shadow: 0 4px 10px rgba(55,56,60,0.16),0 0 2px rgba(55,56,60,0.48);
