import {PayloadAction} from '@reduxjs/toolkit';
import {Filter, LogsState} from '..';

interface Props {
  index: number;
  filter: Filter;
}

export default (state: LogsState, action: PayloadAction<Props>) => {
  state.filters = state.filters.map((filter, index) =>
    index !== action.payload.index ? filter : action.payload.filter,
  );
};
