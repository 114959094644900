import GlobalStyle from 'config/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './config/routes';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './config/redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider {...{store}}>
      <PersistGate {...{loading: null, persistor}}>
        <Routes />
        <GlobalStyle />
      </PersistGate>
    </Provider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root'),
);
