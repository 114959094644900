import {ActionReducerMapBuilder, createAsyncThunk} from '@reduxjs/toolkit';
import {LogsState} from '..';
import api from 'shared/services/api';
import {RootState} from 'config/redux';
import {endOfDay, startOfDay} from 'date-fns';

export const getLogs = createAsyncThunk(
  'logs/get-logs',
  async (_, {rejectWithValue, getState}) => {
    const {filter, authentication} = getState() as RootState;

    const {filters, startDate, endDate, date} = filter;
    const {token} = authentication;

    const params = {} as any;
    const branch = filters.find(({field}) => field === 'branch')!.value;

    filters.forEach(
      ({field, value, operator}) => (params[field] = {value, operator}),
    );

    try {
      return await api({
        method: 'GET',
        url: '/logs',
        params: {
          ...params,
          branch,
          startDate: startOfDay(
            startDate ? new Date(startDate) : new Date(date!),
          ),
          endDate: endOfDay(endDate ? new Date(endDate) : new Date(date!)),
        },
        headers: {Authorization: `Bearer ${token}`},
      });
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  },
);

export const getLogsCases = (builder: ActionReducerMapBuilder<LogsState>) => {
  builder.addCase(getLogs.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getLogs.fulfilled, (state, action) => {
    state.logs = action.payload.data;
    state.lastUpdate = new Date();
    state.loading = false;
  });
  builder.addCase(getLogs.rejected, (state) => {
    state.loading = false;
  });
};
