import React from 'react';

import {
  BrowserRouter,
  Routes as RoutesContainer,
  Route,
  Navigate,
} from 'react-router-dom';

import Logs from '../module/logs/presenter/logs';
import Login from '../module/authentication/presenter/login';
import {useAppSelector} from 'shared/hooks/use-redux';

const Routes: React.FC = () => {
  const {token} = useAppSelector((state) => state.authentication);

  return (
    <BrowserRouter>
      <RoutesContainer>
        {!token && <Route element={<Login />} path="/login" />}
        {token && <Route element={<Logs />} path="/" />}
        <Route path="*" element={<Navigate to={token ? '/' : '/login'} />} />
      </RoutesContainer>
    </BrowserRouter>
  );
};

export default Routes;
