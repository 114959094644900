import colors from 'shared/colors';
import styled from 'styled-components';

interface ContainerProps {
  full: boolean;
}

interface Props {
  active: boolean;
  centerValue: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 15px;
  border-radius: 8px;
  min-width: ${(props) => (props.full ? 350 : 80)}px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  overflow: hidden;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${colors.softGray};
  background-color: ${colors.softGray};
  font-size: 14px;
  margin-bottom: 10px;

  &:focus {
    background-color: ${colors.white};
    outline: 1px solid ${colors.primary};
  }
`;

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div<Props>`
  height: 40px;
  padding: 10px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.active && colors.primary};
  color: ${(props) => (props.active ? colors.white : colors.darkGray)};
  justify-content: ${(props) => (props.centerValue ? 'center' : 'flex-start')};

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${(props) =>
      props.active ? colors.primary : colors.primaryLight};
    color: ${(props) => (props.active ? colors.white : colors.primary)};
  }
`;
