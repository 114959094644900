import {logout} from 'module/authentication/@redux';
import React from 'react';
import {useAppDispatch} from 'shared/hooks/use-redux';

import {Container, Logo, Logout} from './styles';

const Menu: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <Container>
      <Logo src="logo_white.png" />
      <Logout onClick={handleLogout}>Logout</Logout>
    </Container>
  );
};

export default Menu;
