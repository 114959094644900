import React, {useRef, useEffect, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import {Container, Button, Input, Remove} from './styles';
import {
  FieldOptions,
  Filter,
  filterOptions,
  Operators,
} from 'module/logs/@redux';
import {useAppSelector} from 'shared/hooks/use-redux';
import SingleSelect from './components/single-select';
import MultiSelect from './components/multi-select';

interface Props {
  value?: any;
  setValue: (value: any) => void;
  initialFilter?: Filter;
  remove: () => void;
  handleAction: (filter: Filter) => void;
  index: number;
  type?: 'single' | 'multiple' | 'input';
}

const DefaultFilter: React.FC<Props> = ({
  initialFilter,
  remove,
  handleAction,
  index,
  value,
  setValue,
}) => {
  const {filters} = useAppSelector((state) => state.filter);
  const inputRef = useRef<HTMLInputElement>(null);

  const availableFilters = filterOptions
    .filter(({field}) => !filters.find((filter) => filter.field === field))
    .map(({field}) => field);

  const [isShowingFields, showFields] = useState(false);
  const [field, setField] = useState<FieldOptions>();
  const fieldRef = useOnclickOutside(() => {
    showFields(false);
  });

  const currentFilter = filterOptions.find((option) => option.field === field);

  const [isShowingOperators, showOperators] = useState(false);
  const [operator, setOperator] = useState<Operators>(Operators.Equal);
  const operatorRef = useOnclickOutside(() => {
    showOperators(false);
  });

  const [isShowingValues, showValues] = useState(false);
  const valuesRef = useOnclickOutside(() => {
    showValues(false);
  });

  useEffect(() => {
    if (field && field !== initialFilter?.field) {
      setValue(undefined);
      showFields(false);
    }
  }, [field]);

  useEffect(() => {
    showOperators(false);
  }, [operator]);

  useEffect(() => {
    if (field && value === undefined ? true : false) {
      showValues(true);
      if (currentFilter?.type === 'input') inputRef.current?.focus();
    } else showValues(false);
  }, [value, field]);

  useEffect(() => {
    setField(initialFilter?.field);
    setOperator(initialFilter?.operator ?? Operators.Equal);
    setValue(initialFilter?.value);
    showFields(initialFilter?.field ? false : true);
  }, [initialFilter]);

  const onValueChange = (value: string) => {
    setValue(value);

    handleAction({field: field!, value, operator});
  };

  const onOperatorChange = (operator: Operators) => {
    setOperator(operator);
    if (value) handleAction({field: field!, value, operator});
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') inputRef.current?.blur();
  };

  const confirmInput = () => {
    if (value && value !== initialFilter?.value)
      handleAction({field: field!, value, operator});
  };

  return (
    <Container {...{zIndex: 1000 - index}}>
      <Button
        {...{ref: fieldRef}}
        waiting={field ? false : true}
        active={isShowingFields}
        onClick={() => field !== 'branch' && showFields(true)}>
        {field ?? 'Select filter'}
        {isShowingFields && (
          // @ts-ignore
          <SingleSelect
            {...{
              onChange: setField,
              value: field,
              options: availableFilters,
            }}
            search
          />
        )}
      </Button>
      {field && (
        <Button
          {...{ref: operatorRef}}
          active={isShowingOperators}
          onClick={() => field !== 'branch' && showOperators(true)}>
          {operator}
          {isShowingOperators && (
            // @ts-ignore
            <SingleSelect
              {...{
                onChange: onOperatorChange,
                value: operator,
                options: Object.values(Operators),
              }}
              centerValue
            />
          )}
        </Button>
      )}
      {field && currentFilter?.type === 'single' && (
        <Button
          {...{ref: valuesRef}}
          active={isShowingValues}
          onClick={() => showValues(true)}>
          {`${value ?? ''} `}
          {isShowingValues && (
            // @ts-ignore
            <SingleSelect
              {...{
                onChange: onValueChange,
                value,
                options: currentFilter?.values,
              }}
              centerValue
            />
          )}
        </Button>
      )}
      {field && currentFilter?.type === 'multiple' && (
        <Button
          {...{ref: valuesRef}}
          active={isShowingValues}
          onClick={() => showValues(true)}>
          {Array.isArray(value) && value?.join(' or ')}
          {isShowingValues && (
            // @ts-ignore
            <MultiSelect
              {...{
                onChange: onValueChange,
                values: value,
                options: currentFilter?.values,
              }}
              centerValue
            />
          )}
        </Button>
      )}
      {field && currentFilter?.type === 'input' && (
        <Input
          {...{
            onKeyDown,
          }}
          value={value ?? ''}
          ref={inputRef}
          onChange={(e) => setValue(e.target.value)}
          onBlur={confirmInput}
        />
      )}
      {field !== 'branch' && <Remove onClick={remove} />}
    </Container>
  );
};

export default DefaultFilter;
